import React from 'react'
import { graphql } from 'gatsby'
import PageTypes from '../components/page-types/_PageTypes'
import Blocks from '../components/blocks/_Blocks'

const ProductCategoryTemplate = ({ data }) => {
  const node = data.entry
  const products = data.products.nodes.map((product) => {
    const content = JSON.parse(product.content)
    return { ...product, content }
  })
  const content = JSON.parse(node.content)
  const getLocale = (str) => {
    const arr = str.split('/')
    return arr[0]
  }

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  // console.log("Storyblok Content", content)
  return React.createElement(
    PageTypes(content.component),
    {
      key: node.uid,
      content: content,
      name: node.name,
      type: content.component,
      locale: getLocale(node.full_slug),
      full_slug: node.full_slug,
      products: products,
      alternates: node.alternates,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query ProductCategoryTemplateQuery($id: String!, $tagList: [String]) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      full_slug
      alternates {
        full_slug
      }
    }
    products: allStoryblokEntry(
      filter: {
        tag_list: { in: $tagList }
        field_component: { eq: "product_page" }
      }
      sort: { fields: position }
    ) {
      nodes {
        name
        full_slug
        content
      }
    }
  }
`

export default ProductCategoryTemplate
